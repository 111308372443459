//-----------------------------------
// MP hayabusa Plugins
// Auther : Ryo kumagai (MP Inc.)
// URL : www.mpcreative.jp
// Version : 1.4.5.7
//-----------------------------------

// Avoid `console` errors in browsers that lack a console.
(function(w,methods) {
  var method,noop = function(){},
      length = methods.length,
      console = (w.console = w.console || {});
  while (length--) {
    method = methods[length];
    if (!console[method]) console[method] = noop;
  }
}(window,['assert','clear','count','debug','dir','dirxml','error','exception','group','groupCollapsed','groupEnd','info','log','markTimeline','profile','profileEnd','table','time','timeEnd','timeline','timelineEnd','timeStamp','trace','warn']));
(function(w,d,$){
  'use strict';
  $(w).on('load',function(){ console.info('this is onload function') });
  $(d).ready(function(){ console.info('this is documentReady function') });
}(window,document,jQuery));

// Place any jQuery/helper plugins in here.
(function(w,d,$){
  'use strict';

  // 文字数制限(プロトタイプ拡張)
  String.prototype.str_truncate = function(figure,suffix='...'){
    var textLength = this.length;
    var textTrim = this.substr(0, figure)
    if (figure < textLength) {
      return textTrim + suffix
    } else if (figure >= textLength) {
      return textTrim
    }
  }

  /**********
   * メニュー追加
   * 
   * [usage]:
   * 
   * $.addGlobalNav([
   *  [ ${url}, ${PC:disp(number or false)}, ${SP:disp(number or false)} ]  
   * ]);
   */
  $.addGlobalNav = (items,option={}) => {
    const opt = $.extend(true, {
      menus: {
        'pc-gmenu':$('#gmenu ul li'),
        'pc-fmenu':$('#footer .footerlink tr:first-child>td>ul>li'),
        'sp-gmenu':$('.ui-mobile #header_menu ul.contentslist'),
        'sp-fmenu':$('.ui-mobile .homeContents ul.homeContentslist')
      }
    }, option);
    $.each(items,function(key,item){
      $.each(opt.menus,function(key,$menu){
        var $o = $(item[0]).clone(true),
            order = item[1];
        switch(key){
          case 'pc-gmenu':
          case 'pc-fmenu':
            if(!$.UA.is_mobile){
              if(order !== false) $menu.eq(order - 1).after( $o );
            }
            break;
          case 'sp-gmenu':
          case 'sp-fmenu':
            if($.UA.is_mobile){
              $('a',$o).prepend(' ').addClass('icon-arrow-r');
              order = item[2];
              if(order !== false){
                $menu.find('li:eq('+(order - 1)+')').after( $o );
                $menu.listview('refresh');
              }
            }
            break;
          default:
        }
      });
    });
  };


  // トップページ青色ブロックにコンテンツ名のクラス名を付与(PC/SP)
  $.topInit = function(){
    (function(_){
      $(_.target).each(function(){
        var $target = (is_mobile())? $(this):$(this).parent('.block');
        $target.addClass('p-'+_.cont(this)[1]+'Block');
      });
    }({
      cont : function(obj){
        return $(obj).find( (is_mobile())?'h3 > a':'a.wrapLink' ).attr('href').match(/^\/([^\/]+)\//)
      },
      target : (is_mobile())? '.ui-mobile .homeBlock':'#main .block [class*="layout_b_pattern"]'
    }));
  };

  // トップページのブロック整形
  $.topBlockCustomizer = function(base,_){

    var $block = $('.p-'+base+'Block'),
        $articles = $( $block, '#main' ).find('.artloop');
    _ = $.extend(true,{
          button:{
            disp: true,
            text: 'VIEW ALL',
            attr:{
              class: 'button button-center button-normal button-icon-right'
            }
          },
          category:{
            disp: false,
            opt:{
              content: base,
              blockClass: 'p-'+base+'Block__catlist',
              addAll: true,
              linkClass:'button'
            }
          },
          dateSep: '.'
        },_);
    $articles.coverThumb().each(function(){
      var $this = $(this),
          $catetitle = $('.catetitle',this),
          $date = $('.date',this),
          $new = $('.new',this),
          $title = $('h5',this).addClass('title'),
          $catch = $('.catch',this),
          $body = $('p:last-child',this).addClass('body');
      $date.text( $date.text().replace('日','').split(/\/|\.|年|月/).join(_.dateSep) );
      $catetitle.html( $('a:last-child',$catetitle) );
      
      _.itemlayout($this,$catetitle,$date,$new,$title,$catch,$body);
      
    });

    if(_.category.disp){
      $('.element_block > .contents',$block).getCatList( _.category.opt );
    }

    // タイトル処理
    if($.UA.is_mobile){
      var $title = $block.find('h3.ui-bar'),
          titleHtml = (_.title.html)? _.title.html: $title.find('a').html();
      $title.html( titleHtml ).removeClass('ui-bar').addClass('p-'+base+'Block__title');
    }

    var $blockTitle_link = $('.element_block > a.wrapLink:first-child',$block),
        $blockTitle = $blockTitle_link.find('h3').addClass('p-'+base+'Block__title');
    $blockTitle = (_.title.html)? $blockTitle.html( _.title.html ): $blockTitle;
    $blockTitle_link.before( $blockTitle );
    $blockTitle_link.remove();

    if(_.button.disp){
      var button_attr = $.extend(true,{
            href:  '/'+base+'/?mode=list',
          },_.button.attr),
          $button = $('<a>').text(_.button.text).attr(button_attr),
          $buttonWrapper = $('<div/>').attr({ class:'button-wrap text-center'}).append($button);
      $block.append($buttonWrapper);
    }

    return $block;

  };

  // ブロック用にtag除去
  $.strip_tags = function(str){
    // return $('<div />').html(html).text();
    str = str.toString();
    return str.replace(/<\/?[^>]+>/gi, '');
  }

  // スクロール監視
  $.scrollspy = function(option){
    var opt = $.extend(true, {
      interval: 300,
      trigger: 1000,
      duration: 5000,
      onBefore: function(){},
      onAfter: function(){}
    }, option),
    checkTimer,
    runTimer,
    is_run = false,
    pos = 0,
    checkPos = function(){
      pos = w.pageYOffset;
      if(pos < opt.trigger) opt.onBefore(pos);
      if(pos >= opt.trigger) opt.onAfter(pos);
      checkTimer = setTimeout(checkPos,opt.interval);
    },
    runCheck = function(){
      if(!is_run){
        checkPos();
        is_run = true;
        clearTimeout(runTimer);
        runTimer = setTimeout(function(){
          clearTimeout(checkTimer);
          is_run = false;
        },opt.duration);
      }
    };
    $(w).on('scroll',runCheck);
  };

  // ページングテキスト差替え
  $.pagingText = function(option){
    var opt = $.extend(true,{
          prev:'前のページヘ',
          next:'次のページヘ',
          list:'一覧へ戻る'
        },option),
        changeTxt = function($obj,sels){
          for(var sel in sels ){
            $(sels[sel],$obj).text(opt[sel]);
          }
        };
    if( $.UA.is_pc && $('.paginationbox').length ){
      changeTxt(
        $('.paginationbox'),{
        prev:'.prev',next:'.next',list:'.backbtn>a'
      });
    }
    if( $.UA.is_mobile && $('.pageingBtnBlock').length ){
      changeTxt(
        $('.pageingBtnBlock'),{
        prev:'.pageingBtnBack>span',next:'.pageingBtnNext>span',list:'.backbtn>a'
      });
    }
  };

  //coverThumb
  $.fn.coverThumb = function(option){
    var opt = $.extend(true,{
      thumbimg: '.thum_img img',
      thumbCss:{
        width:'100%',
        height:'100%',
        opacity:'0'
      }
    },option);
    return this.each(function(){
      var $this = $(this),
          thumbSrc = $this.find(opt.thumbimg).attr('src');
      if(thumbSrc==undefined || !thumbSrc) return;
      var rawImagePath = thumbSrc.replace(/thumb-([^\/]+)(\.jpe?g|gif|png)$/i,'$1$2');
      $this.find('.thum_img').addClass('cover-thumb').css({
        'background': 'url('+rawImagePath+') no-repeat center',
        'background-size':'cover'
      }).find('img').attr({
        src: rawImagePath
      }).css(opt.thumbCss);
    });
  }

  //svgスプライト読み込み
  $.loadSVG = function(option){
    var opt = $.extend(true,{
                svg:'/asset/svg/sprite.symbol.svg',
                $target:'body',
                callback:function(svg){
                  var div = d.createElement("div");
                  div.innerHTML = new XMLSerializer().serializeToString(svg.documentElement);
                  d.body.insertBefore(div, d.body.childNodes[0]);
                  loadSVG.resolve(svg);
                }
              },option),
        loadSVG = new $.Deferred;
    $.get(opt.svg).done(opt.callback);
    return loadSVG;//promise
  };

  // bodyClass追加プラグイン
  // [option]
  // - target: jquery selector
  //     クラスを付与するDOM(def:'body')
  // - prefix: str
  //     クラスの接頭辞(def:'is--')
  // - classes: arr
  //     デフォルトで付与するクラス(def:[])
  // - mode.home: boolean
  //     トップページ用クラスを付与するか(def:true)
  // - mode.content: boolean
  //     コンテンツクラスを付与するか(def:true)
  // - mode.list: boolean
  //     リストクラスを付与するか(def:true)
  // - mode.cat: boolean
  //     カテゴリIDクラスを付与するか(def:true)
  // - mode.detail: boolean
  //     詳細ページクラスを付与するか(def:true)
  // - mode.article: boolean
  //     記事IDクラスを付与するか(def:true)
  // - mode.contentTop: boolean
  //     コンテンツトップクラスを付与するか(def:true)
  //     ※falseの場合、コンテンツトップ時には記事IDクラスが付きません
  // - pathname: str
  //     現在のファイルパス(def:location.pathname)
  // - params: str
  //     現在のクエリパラメータ(def:location.search)
  $.bodyClass = function(option){
    var opt = $.extend(true,{
          target: 'body',
          prefix: 'is--',
          classes: [],
          mode:{
            home:       true,
            content:    true,
            list:       true,
            cat:        true,
            detail:     true,
            article:    true,
            contentTop: true
          },
          pathname: location.pathname,
          params: location.search
        },option),
        is_home = (function(p){return p=='/'}(opt.pathname)),
        content = (function(d){return (d)?d[1]:false}( opt.pathname.match(/^\/([^\/]+)\//) )),
        is_filename = (function(f){return (f)?f[1]:false}( opt.pathname.match(/([^\/]+)\.(html?|php)$/i) )),
        add = function(cls){ opt.classes.push(opt.prefix+cls)},
        add_contentTop = function(xml){
          var topArticle = $('REC_NO',xml).filter(function(){
            return $('DETAIL_LIST',this).text() == 1;
          });
          topArticle = (topArticle.length>0)? topArticle.attr('id'): false;
          if(topArticle){
            if(opt.params ==''){
              add('contentsTop');
              add('article-'+topArticle);
            }
          }else{
            if(opt.params ==''){
              add('list');
            }
          }
          urlparse.resolve(opt.classes);
        },
        params = {},
        urlparse = new $.Deferred;
    if(opt.params){
      $.map(opt.params.substr(1).split('&'),function(v,i){
        var keyval = v.split('=');
        params[keyval[0]] = keyval[1];
      });
    }
    
    if( opt.mode.home && is_home )                  add('home');
    if( opt.mode.content && content )               add(content);
    if( opt.mode.list && params.mode=='list')       add('list');
    if( opt.mode.list && params.page>0)             add('list');
    if( opt.mode.detail && params.mode=='detail' )  add('detail');
    if( !opt.mode.cat && params.cat )               delete params.cat;
    if( !opt.mode.article && params.article )       delete params.article;
    if( !opt.params && !is_home && is_filename )    add(is_filename);
    delete params.mode;
    if( opt.mode.contentTop && !is_filename && !is_home ){
      $.getModule().then(function(result){
        return $.get('/hs_data/'+result[content].module+'/data.xml');
      }).then(add_contentTop);
    }else{
      urlparse.resolve(opt.classes);
    }
    $.each(params,function(i,v){ add(i+'-'+v) });
    $(opt.target).addClass(opt.classes.join(' '));
    urlparse.promise().done(function(classes){
      $(opt.target).addClass(opt.classes.join(' '));
    });
  };

  // カレントメニュープラグイン
  // [option]
  // - currentClass: str
  //     カレントメニューに付与するクラス名設定(def:'current')
  // - item: jquery selector
  //     クラスを付与するアイテム(def:'a')
  // - mode: 0|1
  //     0:完全一致か1:ディレクトリマッチか(def:0)
  // - default: num
  //     見つからなかった場合のデフォルトメニュー位置(def:0)
  // - url: str
  //     現在のURLを偽装する場合に変更できます(def:location.pathname+location.search)
  // [戻り値]
  // jQuery Object
  $.fn.currentMenu = function(option){
    return this.each(function(){
      var opt = $.extend(true,{
            currentClass: 'current',
            item: 'a', // loop item
            mode: 0, // 0: parfectMatch, 1:directoryMatch
            default: 0,
            url: location.pathname+location.search
          },option,$(this).data()),
          addCurrent = function(dom){
            $(dom).addClass(opt.currentClass);
          },
          flushCurrent = (function(o){o.removeClass(opt.currentClass)}( $(opt.item,this) ));
      $(opt.item,this).each(function(){
        var href = this.getAttribute('href') || $(this).children('a').attr('href'),
            url = opt.url;
        if( opt.mode !== 0 ){
          href = href.replace(/^\/([^/]+)\/(.*)?/,'/$1/');
          url = url.replace(/^\/([^/]+)\/(.*)?/,'/$1/');
        }
        if( url == href ) addCurrent(this);
      });
      // fallback
      if( $('.'+opt.currentClass,this).length == 0 ) addCurrent( $(opt.item,this).eq(opt.default) );
    });
  };

  // SP 自動挿入フッターナビ
  $.fn.spFooterNav = function(option){
    var opt = $.extend(true,{
          method:'prepend',
          data:{
            tel:'電話番号が入ります。',
            name:'会社名が入ります。',
            inquiry:'/inquiry/',
            title:{
              tel:'お電話でのお問い合わせはこちら',
              form:'お問い合わせ・資料請求はこちら'
            }
          },
          on:{
            makeNav:function(data){
              var tel = '<p>'+data.title.tel+'</p><a data-tel="'+data.tel+'" href="tel:'+data.tel+'" class="tel">'+data.tel+'</a>',
                  contact = '<a href="'+data.inquiry+'" class="contact">'+data.title.form+'</a>';
              return $('<div class="spFooterNav">'+((data.tel)?tel:'')+((data.inquiry)?contact:'')+'</div>');
            }
          }
        },option),
        $self = this;
    if(!$.UA.is_pc){
      this.each(function(){
        var $this = $(this),
            $nav = opt.on.makeNav(opt.data);
        $this[opt.method]($nav);
      });
    }else{
      console.info('spFooterNav plugin is skipped reason: device=PC');
    }
    return $self;
  };

  // ファイル読み込みプラグイン
  $.require = function(option){
    var opt = $.extend(true,{
          '*':'', // ALL device and ALL page
          pc:{
            '*':'', // PC device and ALL page
            'contentName':['(js/css)path/to/file']
          },
          sp:{
            'contentName':['(js/css)path/to/file']
          },
          tags:{
            js:{
              element:'script',
              pathAttr: 'src',
              attr:{'async': false}
            },
            css:{
              element: 'link',
              pathAttr: 'href',
              attr:{'rel': 'stylesheet'}
            }
          },
          disableCache: false
        },option),
        device = ($.UA.is_pc )? 'pc':'sp',
        contentName = location.pathname.match(/^(\/)([^\/]+)?/),
        contentName = (contentName[1]||'')+(contentName[2]||''),
        unixtime = new Date().getTime(),
        timestamp = (opt.disableCache)? '?timestamp='+ unixtime : '';
    var writefile = function(i,file){
      var filetype = file.match(/^\(?(js|css)?\)?.+(js|css)/),
          filetype = filetype[1] || filetype[2];
      if(filetype != 'js' && filetype != 'css' ){
        console.warn('[$.require] unknown filetype. please prepend "(js)" or "(css)"\n"%s"',file);
        return false;
      }
      var path = file.replace(/^(\(\w+\))?/,'') + timestamp ,
          element = document.createElement(opt.tags[filetype].element),
          firstElement = document.getElementsByTagName(opt.tags[filetype].element)[0];
      $.extend(element,opt.tags[filetype].attr);
      element[opt.tags[filetype].pathAttr] = path;
      firstElement.parentNode.insertBefore(element, null);
      console.info('required %c%s%c in %c%s%c','font-weight: bold',path,'','font-weight: bold',filetype,'');
    };
    if(opt['*'] != undefined && $.type(opt['*']) == 'array' ){
      $.each(opt['*'],writefile);
    }
    if($.type(opt['*']) == 'object' && opt['*'][contentName] != undefined ){
      $.each(opt['*'][contentName],writefile);
    }
    if(opt[device]['*'] != undefined){
      $.each(opt[device]['*'],writefile);
    }
    if(opt[device][contentName] != undefined){
      $.each(opt[device][contentName],writefile);
    }
  };

  // エージェント判断プラグイン
  $.UA = (function(ua){
    var a = {
          'device':'',
          'browser':'',
          'version':'',
          'os':'unknown'
        },
        J = function(a){return ua.indexOf(a)>0};
    if     ( J('iphone') && !J('ipad') )          a.device = 'iphone';
    else if( J('ipad') )                          a.device = 'ipad';
    else if( J('android') && J('mobile') )        a.device = 'androidmobile';
    else if( J('android') && !J('mobile') )       a.device = 'androidtablet';
    else                                          a.device = 'pc';

    if     ( J('macintosh') )                     a.os = 'mac';
    else if( J('windows') )                       a.os = 'win';
    else if( J('android') )                       a.os = 'android';
    else if( J('iphone os') || J('like mac os') ) a.os = 'ios';

    if     ( J('chrome') )                        a.browser = 'chrome';
    else if( J('firefox') )                       a.browser = 'firefox';
    else if( J('safari') )                        a.browser = 'safari';
    else if( J('msie') )                          a.browser = 'ie';

    if     ( J('msie 8.0') )                      a.version = '8';
    else if( J('msie 9.0') )                      a.version = '9';
    else if( J('msie 10.0') )                     a.version = '10';
    else if( J('trident/7.0') && J('rv:11'))      a.version = '11';
    a.is_pc = (function(){
        return (a.device == 'pc' || a.device == 'ipad')? true : false;
      }());
    a.is_mobile = (function(){
        return (a.device != 'pc' && a.device != 'ipad')? true : false;
      }());
    return a;
  }( navigator.userAgent.toLowerCase() ));
  

  //justifyプラグイン
  $.fn.justify = function(option){
    var opt = $.extend({
      lastTag:'<span>',
      lastClass:'lastLetter',
      lastCSS:{
        'letter-spacing':0
      }
    },option);
    var maxlength=0;
    this.each(function(){
      var thisLength = $(this).text().length;
      if(thisLength > maxlength) maxlength = thisLength;
    });
    return this.each(function(){
      var arrText = $(this).text().split(''),
          length = arrText.length,
          lastLetter = arrText.pop(),
          ls = parseFloat($(this).css('letter-spacing')),
          $last = $(opt.lastTag).addClass(opt.lastClass).text(lastLetter);
      ls = ls / parseFloat($(this).css('font-size'));
      $(this).html(arrText.join(''))
        .append($last.css(opt.lastCSS))
        .css({
          'letter-spacing':((maxlength - length)/(length-1))+ls+'em'
        });
    });
  }

  // HTML読み込みプラグイン
  $.fn.loadHtml = function(option){
    var opt = $.extend({
      src: '/asset/html/',
      extention: '.html'
    },option);
    return this.each(function(){
      var $this = $(this),
          this_opt = $.extend(opt,$this.data());
      w.load[this_opt.loadHtml] = new $.Deferred;
      $.get(this_opt.src+this_opt.loadHtml+this_opt.extention,function(html){
        $this.replaceWith(html);
        console.info('%c%s%c is loaded','font-weight: bold',this.url,'');
        w.load[$this[0].dataset.loadHtml].resolve(html);
        $(w).trigger($this.data().loadHtml+'.load');
      });
    });
  };

  //モジュール情報読み込み関数
  $.getModule = function(){
    var d = new $.Deferred();
    $.get('/hs_data/module_info.xml').done(function(module_data){
      var modules = {};
      $.each( $('REC_NO',module_data) ,function(key,val){
        if($('MODULE_FLG',val).text() == 1){
          modules[$('MODULE_TITLE',val).text()] = {
            module:   $('MODULE',val).text(),
            name:     $('MODULE_NAME',val).text(),
            turn:     $('TURN',val).text()
          };
        }
      });
      d.resolve(modules,module_data);
    });
    return d.promise();
  };

  $.getCategory = function(option){
    var opt = $.extend(true,{
          content: false,
          module: false
        },option),
        getData = new $.Deferred();
    $.get('/hs_data/'+opt.module+'/cat1.xml').done(function(cat_data){
      var categories = [];
      $.each( $('REC_NO',cat_data) ,function(key,val){
        categories.push({
          id:   $(val).attr('id'),
          name:     $('CATEGORY',val).text(),
          turn:     $('TURN',val).text()
        });
      });
      categories.sort(function(val1,val2){
        var v1 = parseInt(val1.turn),
            v2 = parseInt(val2.turn);
        return (v1 > v2 ? 1:-1);
      });
      getData.resolve(categories);
    });
    return getData.promise();
  };

  $.getArticle = function(option){
    var opt = $.extend(true,{
          content: false,
          module: false
        },option),
        nowDate = parseInt(new Date/1000),
        getData = new $.Deferred();
    $.get('/hs_data/'+opt.module+'/data.xml').done(function( contents_data ){
        var pubdata = $.map( $('REC_NO',contents_data) ,function(n,i){
              var id =          $(n).attr('id'),
                  date =        $('DATE',n).text(),
                  title =       $('SUBJECT',n).text(),
                  href =        '/'+opt.content+'/?mode=detail&article='+$(n).attr('id'),
                  thumb =       $('THUMB_IMG',n).text(),
                  openflg =     $('OPEN_FLG',n).text(),
                  category =    $('CATEGORY',n).text(),
                  desc =        $('PAGE_DESCRIPTION',n).text(),
                  detail_list = $('DETAIL_LIST',n).text(),
                  body =        $.strip_tags( $('BODY',n).text() );
                // 条件にあった記事だけ抽出
                if( openflg == 1 && date <= nowDate ){
                  return {id:id,date:date,title:title,desc:desc,href:href,thumb:thumb,category:category,detail_list:detail_list,body:body};
                } 
            });
        // 日付順に並び替え
        pubdata.sort(function(val1,val2){
          if(val1.date != val2.date){
            return (val1.date < val2.date ? 1:-1);
          }else{
            return (val1.id < val2.id ? 1:-1);
          }
        });
        getData.resolve(pubdata);
    });
    return getData.promise();
  };

  // カテゴリリスト読み込みプラグイン
  $.fn.getCatList = function(option){
    var defaultOpt = {
          content: 'information',
          length: -1,
          blockTag: '<ul/>',
          itemTag: '<li/>',
          blockClass: 'informationCatlist',
          currentClass: 'current',
          linkClass:'',
          method: 'before',
          addAll: false,
          allLabel:'ALL'
        },
        opt = $.extend(defaultOpt,option),
        $elm = this,
        dispCat = function(content,cat){
          var $container = $(opt.blockTag).addClass(opt.blockClass),
              is_current = function(className,url){
                var currenturl = location.pathname+location.search;
                if(url == currenturl+'?mode=list') return className;
                return (url == currenturl) ? className : '';
              };
          $.each(cat,function(i,val){
            if(opt.length != -1 && opt.length <= i || val.name == '') return;
            var href = '/'+content+'/?mode=list'+( (val.id!='all' && val.id!='')?'&cat='+val.id:'' ),
                $item = $(opt.itemTag).attr({
                  'class': 'cat'+val.id+' '+opt.blockClass+'__item'
                }),
                $link = $('<a />').text(val.name).attr({
                  href: href,
                  'class': is_current(opt.currentClass,href)+' '+opt.blockClass+'__link '+opt.linkClass,
                  title:val.name
                });
            $link.appendTo($item);
            $item.appendTo($container);
          });
          $elm.each(function(){
            switch(opt.method){
              case 'prepend': $container.prependTo($(this));
                break;
              case 'append': $container.appendTo($(this));
                break;
              case 'after': $(this).after($container);
                break;
              case 'before':
              case 'default': $(this).before($container);
                break;
            }
          });
        };
    // 非同期処理
    $.getModule()
    .then(function(modules){
      return $.getCategory({content:opt.content,module: modules[opt.content].module});
    }).then(function(cat){
      if(opt.addAll){
        cat.unshift({id:'all',name:opt.allLabel,turn:0});
      }
      dispCat(opt.content,cat);
    });
    return $elm;
  };

  // 記事リスト読み込みプラグイン
  $.fn.getBlock = function(option){
    var defaultOpt = {
          content: 'information',
          length: -1,
          blockTag: '<ul/>',
          itemTag: '<li/>',
          blockClass: 'informationBlock',
          currentClass: 'current',
          linkClass:'',
          method: 'prepend',
          title: '<h3>コンテンツメニュー</h3>',
          bodyLength: 100,
          date: false,
          thumb: false,
          catch: false,
          body: false,
          classname: function(name,opt){ return 'p-'+opt.content+'__'+name },
          beforeRender: function(articles,opt){ return articles },
          renderLink: function($link,$thumb,$date,$subject,$catch,$body){
            return $link.append( $thumb, $date, $subject, $catch, $body );
          },
          renderItem: function(item,$link,opt,i,$item){
            return $item.append($link);
          }
        },
        opt = $.extend(defaultOpt,option),
        $elm = this,
        deferred = new $.Deferred(),
        displayBlock = function(articles,currentpath){
          var $nav = $(opt.blockTag).addClass(opt.blockClass),
              d = new $.Deferred();
          articles = opt.beforeRender(articles,opt);
          $.each(articles,function(i,item){
            if(opt.length != -1 && opt.length <= i) return;
            var $item = $(opt.itemTag),
                $link = $('<a/>',{
                  'href': item.href,
                  'class': (currentpath == item.href)? opt.currentClass:''
                }),
                date = new Date(item.date * 1000),
                dateText = date.getFullYear() +'.'+ ("0"+(date.getMonth() + 1)).slice(-2) +'.'+ ("0"+date.getDate()).slice(-2),
                $date = (opt.date)? $('<span />',{class:opt.classname('date',opt)}).text( dateText ) : false,
                $subject = $('<span>',{class:opt.classname('subject',opt)}).text(item.title),
                $img = (opt.thumb)? $('<img />',{src:item.thumb.replace(/([^/]+)\.(jpe?g|gif|png)$/,'thumb-$1.$2'),alt:item.title}) : false,
                $thumb = (opt.thumb)? $('<figure />',{class:opt.classname('thumb',opt),style:'background-image: url(\''+item.thumb+'\')'}).append( $img ): false,
                $catch = (opt.catch)? $('<span />',{class:opt.classname('catch',opt)}).text(item.desc) : false,
                bodytext = (item.body.length > opt.bodyLength )? $.trim(item.body).substr(0, (opt.bodyLength - 3) )+'...' : item.body,
                $body = (opt.body)? $('<span />',{class:opt.classname('body',opt)}).html( bodytext ) : false;
            if(item.detail_list == 1 && currentpath == '/'+opt.content+'/') $link.addClass(opt.currentClass);
            $link = opt.renderLink($link,$thumb,$date,$subject,$catch,$body).addClass(opt.linkClass);
            $item = opt.renderItem(item,$link,$item,opt,i);
            $item.appendTo($nav);
          });
          $elm.each(function(){
            $(this)[opt.method]($nav);
            switch(opt.method){
              case 'replaceWith':
                if(opt.title) $nav.before( $(opt.title) );
                break;
              default:
                if(opt.title) $(this).prepend( $(opt.title) );
            }
          });
          d.resolve();
          return d.promise();
        };
    // 非同期処理
    $.getModule()
    .then(function(modules){
      return $.getArticle({content:opt.content,module:modules[opt.content].module});
    })
    .then(function(articles){
      displayBlock(articles,location.pathname+location.search);
    })
    .done(function(){
      deferred.resolve($elm);
    });
    return deferred.promise();
  };

  // フリーブロック読み込みプラグイン
  $.getFreeBlock = function(option){
    var opt = $.extend({
          xml:'b_content_disp.xml',
          id:'',
          xmltag : is_mobile() ? 'M_FREECONTENTS':'FREECONTENTS',
          callback: function(block){return block}
        },option),
        d = new $.Deferred(),
        block;
    $.get('/hs_data/'+opt.xml).done(function(data){
      if(opt.id) block = $('REC_NO[id="'+opt.id+'"]',data).find( opt.xmltag ).text();
      block = opt.callback(block);
      d.resolve(block);
      console.info('%c%s%c is loaded','font-weight: bold',opt.xml+'#'+opt.id,'');
    });
    return d.promise();
  };

}(window,document,jQuery));

;(function(w,d,$){
  'use strict';
  
  //-----------------------------------
  // googlemap読み込みプラグイン
  // Auther : Ryo kumagai (MP Inc.)
  // URL : www.mpcreative.jp
  // Version : 1.2.0
  //-----------------------------------
  $.fn.googlemap = function(option){
    var _optionTemplate = {
          zoom:14,
          scrollwheel:false,
          mapTypeControl: false,
          zoomControl:false,
          streetViewControl:false,
          tooltip:true,
          markers:[
            {
              title:'',
              address:'',
              latlng:''
            }
          ],
          centerAdjust:{
            lat:6.5,
            lng:15
          },
          styleObj:{
            name:'',
            style:''
          }
        },
        defaultOpt = $.extend(true,_optionTemplate,{ // reset option template
          markers:[''],
          styleObj:[''],
          centerAdjust:false
        }),
        opt = $.extend(true,defaultOpt,option),
        maps_init = (function($,opt,currentInfoWindow){
          var geocoder = (typeof google == 'object')? new google.maps.Geocoder() :{};
          return {
            createMap: function(obj) {
              var _this = this,
                  markers = opt.markers,
                  mapOpts = {
                    zoom : $(obj).data('zoom') || opt.zoom,
                    scrollwheel: opt.scrollwheel,
                    mapTypeControl: opt.mapTypeControl,
                    zoomControl: opt.zoomControl,
                    streetViewControl: opt.streetViewControl
                  };
              var map = new google.maps.Map( obj, mapOpts );
              if(markers.length <= 1 && $(obj).data('address')!=undefined){
                markers[0] = $(obj).data();
              }
              var markerLength = markers.length;
              var $d_setMarker = new $.Deferred;
              var bounds = new google.maps.LatLngBounds();
              var setMarker = function(){
                $.each(markers,function(i,marker){
                  var $d_getAddrs = new $.Deferred;
                  var latlng = [];
                  var is_last = ((i+1) == markerLength)? true :false;
                  var getaddress = function(i){
                    geocoder.geocode({'address':marker.address},function(results,status){
                      if (status == google.maps.GeocoderStatus.OK) {
                        latlng[0] = results[0].geometry.location.lat();
                        latlng[1] = results[0].geometry.location.lng();
                        // if(is_last){
                        //   $d_setMarker.resolve();
                        // }
                        $d_getAddrs.resolve(latlng);
                      } else { // ジオコーディングが成功しなかった場合
                        console.error('Geocode was not successful for the following reason: ' + status);
                      }
                    });
                    return $d_getAddrs;
                  };
                  if(marker.latlng != undefined ){
                    latlng = marker.latlng.split(',');
                    _this.getmapByLatlng( map, latlng, marker, bounds ).done(function(){
                      if(is_last){
                        $d_setMarker.resolve(bounds);
                      }
                    });
                  }else{
                    getaddress(i).done(function(result){
                      _this.getmapByLatlng( map, latlng, marker, bounds ).done(function(){
                        $d_setMarker.resolve(bounds);
                      });
                    });
                  }
                });
                return $d_setMarker;
              };
              setMarker().done(function(bounds){
                if(opt.centerAdjust != ''){
                  var centerLatlng = bounds.getCenter(),
                      newLatlng = {
                        lat:centerLatlng.lat() + parseFloat(opt.centerAdjust.lat),
                        lng:centerLatlng.lng() + parseFloat(opt.centerAdjust.lng)
                      };
                  var newLatlngobj = new google.maps.LatLng(newLatlng.lat,newLatlng.lng);
                  map.setCenter( newLatlngobj );
                }else if(markers.length > 1){
                  map.fitBounds(bounds);
                }
              });
              if(opt.styleObj != ''){
                _this.setStyle( map, opt.styleObj );
              }
            },
            getmapByLatlng: function(map,latlng,markerOpt,bounds){
              var latlngobj = new google.maps.LatLng( latlng[0], latlng[1] ),
                  zIndex = markerOpt.zIndex || 1,
                  $d_getmapByLatlng = new $.Deferred;

              bounds.extend(latlngobj);
              if(opt.markers.length == 1){
                map.setCenter(latlngobj);
              }
              var marker = new google.maps.Marker({
                map: map,
                position: latlngobj,
                zIndex: zIndex
              });
              if(markerOpt.icon !== undefined && markerOpt.icon != ''){
                marker.setOptions({icon:markerOpt.icon});
                if(markerOpt.iconSize !== undefined && markerOpt.icon != ''){
                  var iconSize = markerOpt.iconSize.split(',');
                  marker.setOptions({icon:{
                    url: markerOpt.icon,
                    scaledSize : new google.maps.Size(iconSize[0],iconSize[1])
                  }});
                }
              }
              if(opt.tooltip){
                var tooltipOpts = {
                  content : '\
                    <div class="poi-info-window gm-style">\
                      <div style="font-size:1.2rem;">\
                        <div class="title full-width" style="font-size:1.2em;">'+markerOpt.title+'</div>\
                        <div class="address">\
                          <div class="address-line full-width">'+markerOpt.address+'</div>\
                        </div>\
                      </div>\
                      <div class="view-link">\
                        <a target="_blank" style="color: #427fed;" href="https://maps.google.com/maps?ll='+latlng.join()+'&amp;q='+latlng.join()+'&amp;z='+opt.zoom+'&amp;hl=ja">\
                          <span>Googleマップで見る</span>\
                        </a>\
                      </div>\
                    </div>\
                    ',
                };
                var tooltip = new google.maps.InfoWindow(tooltipOpts);
                google.maps.event.addListener(marker, "click", function(){
                  //先に開いた情報ウィンドウがあれば、closeする
                  if (currentInfoWindow) {
                    currentInfoWindow.close();
                  }
                  //情報ウィンドウを開く
                  tooltip.open(map, marker);
                  currentInfoWindow = tooltip;
                });
              }
              return $d_getmapByLatlng.resolve();
            },
            setStyle: function(map,mapStyle){
              var g_mapStyle = new google.maps.StyledMapType(mapStyle.style, mapStyle.name);
              map.mapTypes.set( mapStyle.name, g_mapStyle);
              map.setMapTypeId( mapStyle.name);
            }
          };
        }(jQuery,opt,null));
    this.reload = function(reloadOption){
      opt = $.extend(true,opt,reloadOption);
      maps_init.createMap(this[0]);
    };
    return this.each(function(){
      maps_init.createMap(this);
    });
  };
}(window,document,jQuery));